module.exports = [
  {
    "schema": "Address",
    "file": "schemas/Address.json",
    "examples": [
      "examples/general/Address/address.min.example.json",
      "examples/general/Address/address1.example.json",
      "examples/general/Address/address2.example.json"
    ]
  },
  {
    "schema": "Agent",
    "file": "schemas/Agent.json",
    "examples": [
      "examples/general/Agent/agent.example.json",
      "examples/general/Agent/agent1.min.example.json",
      "examples/general/Agent/agent2.min.example.json"
    ]
  },
  {
    "schema": "Basic Event",
    "file": "schemas/BasicEvent.json",
    "examples": [
      "examples/eventdata/BasicEvent/basicevent.example.json",
      "examples/eventdata/BasicEvent/basicevent.min.example.json"
    ]
  },
  {
    "schema": "Contact Point",
    "file": "schemas/ContactPoint.json",
    "examples": [
      "examples/general/ContactPoint/contact1.example.json",
      "examples/general/ContactPoint/contact1.min.example.json",
      "examples/general/ContactPoint/contact2.min.example.json",
      "examples/general/ContactPoint/contact3.min.example.json",
    ]
  },
  {
    "schema": "Event",
    "file": "schemas/Event.json",
    "examples": [
      "examples/eventdata/Event/event1.example.json",
      "examples/eventdata/Event/event1.min.example.json",
      "examples/eventdata/Event/event2.example.json",
      "examples/eventdata/Event/event2.min.example.json"
    ]
  },
  {
    "schema": "Event Series",
    "file": "schemas/EventSeries.json",
    "examples": [
      "examples/eventdata/EventSeries/series.example.json",
      "examples/eventdata/EventSeries/series.min.example.json"
    ]
  },
  {
    "schema": "Hours Specification",
    "file": "schemas/HoursSpecification.json",
    "examples": [
      "examples/general/HoursSpecification/hours.min.example.json",
      "examples/general/HoursSpecification/hours1.example.json",
      "examples/general/HoursSpecification/hours2.example.json"
    ]
  },
  {
    "schema": "Lift",
    "file": "schemas/Lift.json",
    "examples": [
      "examples/mountaindata/Lift/lift.example.json",
      "examples/mountaindata/Lift/lift.min.example.json"
    ]
  },
  {
    "schema": "Lift Ticket Type",
    "file": "schemas/LiftTicketType.json",
    "examples": [
      "examples/mountaindata/LiftTicketType/ticket1.example.json",
      "examples/mountaindata/LiftTicketType/ticket2.example.json",
      "examples/mountaindata/LiftTicketType/ticket3.example.json",
      "examples/mountaindata/LiftTicketType/ticket4.example.json"
    ]
  },
  {
    "schema": "Line String",
    "file": "schemas/LineString.json",
    "examples": [
      "examples/geojson/LineString/linestring.example.json"
    ]
  },
  {
    "schema": "Media Object",
    "file": "schemas/MediaObject.json",
    "examples": [
      "examples/general/MediaObject/audio.example.json",
      "examples/general/MediaObject/audio.min.example.json",
      "examples/general/MediaObject/document.example.json",
      "examples/general/MediaObject/document.min.example.json",
      "examples/general/MediaObject/image.example.json",
      "examples/general/MediaObject/image.min.example.json",
      "examples/general/MediaObject/video.example.json",
      "examples/general/MediaObject/video.min.example.json"
    ]
  },
  {
    "schema": "Mountain Area",
    "file": "schemas/MountainArea.json",
    "examples": [
      "examples/mountaindata/MountainArea/area.example.json",
      "examples/mountaindata/MountainArea/area.min.example.json"
    ]
  },
  {
    "schema": "Offering",
    "file": "schemas/Offering.json",
    "examples": [
      "examples/general/Offering/offering.example.json",
      "examples/general/Offering/offering.min.example.json"
    ]
  },
  {
    "schema": "Operations Report",
    "file": "schemas/OperationsReport.json",
    "examples": [
      "examples/mountaindata/OperationsReport/operationsreport.example.json",
      "examples/mountaindata/OperationsReport/operationsreport.min.example.json"
    ]
  },
  {
    "schema": "Place",
    "file": "schemas/Place.json",
    "examples": [
      "examples/general/Place/place.example.json"
    ]
  },
  {
    "schema": "Point",
    "file": "schemas/Point.json",
    "examples": [
      "examples/geojson/Point/point.example.json"
    ]
  },
  {
    "schema": "Polygon",
    "file": "schemas/Polygon.json",
    "examples": [
      "examples/geojson/Polygon/polygon.example.json"
    ]
  },
  {
    "schema": "Price Specification",
    "file": "schemas/PriceSpecification.json",
    "examples": [
      "examples/general/PriceSpecification/price.min.example.json",
      "examples/general/PriceSpecification/price1.example.json",
      "examples/general/PriceSpecification/price2.example.json",
      "examples/general/PriceSpecification/price3.example.json",
      "examples/general/PriceSpecification/price4.example.json"
    ]
  },
  {
    "schema": "Snow Report",
    "file": "schemas/SnowReport.json",
    "examples": [
      "examples/mountaindata/SnowReport/snowreport.example.json",
      "examples/mountaindata/SnowReport/snowreport.min.example.json"
    ]
  },
  {
    "schema": "Snowpark",
    "file": "schemas/Snowpark.json",
    "examples": [
      "examples/mountaindata/Snowpark/snowpark.example.json",
      "examples/mountaindata/Snowpark/snowpark.min.example.json"
    ]
  },
  {
    "schema": "Ticket Report",
    "file": "schemas/TicketReport.json",
    "examples": [
      "examples/mountaindata/TicketReport/ticketreport.example.json",
      "examples/mountaindata/TicketReport/ticketreport.min.example.json"
    ]
  },
  {
    "schema": "Ticket Type",
    "file": "schemas/TicketType.json",
    "examples": [
      "examples/general/TicketType/ticketype.example.json"
    ]
  },
  {
    "schema": "Trail",
    "file": "schemas/Trail.json",
    "examples": [
      "examples/mountaindata/Trail/trail.example.json",
      "examples/mountaindata/Trail/trail1.min.example.json",
      "examples/mountaindata/Trail/trail2.min.example.json"
    ]
  },
  {
    "schema": "Venue",
    "file": "schemas/Venue.json",
    "examples": [
      "examples/eventdata/Venue/venue.example.json",
      "examples/eventdata/Venue/venue.min.example.json"
    ]
  },
  {
    "schema": "Venue Offering",
    "file": "schemas/VenueOffering.json",
    "examples": [
      "examples/eventdata/VenueOffering/venueoffering.example.json",
      "examples/eventdata/VenueOffering/venueoffering1.min.example.json",
      "examples/eventdata/VenueOffering/venueoffering2.min.example.json"
    ]
  }
];
