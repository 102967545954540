import React from 'react';
import Validator from './Validator';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';


class App extends React.Component {
  render () {
    return (
      <div className="App">
        <AppBar position="static" color="default">
          <Toolbar>
            <div className="logo-container">
              <img className="logo" src="/logo.png" alt="AlpineBits">
              </img>
            </div>
            <Typography variant="h6" color="inherit">
              DestinationData Validator
            </Typography>
          </Toolbar>
        </AppBar>

        <Container maxWidth={false}>
          <div className="App-instructions">
            <Typography variant="body1" color="inherit">
              An online, interactive JSON Schema validator tailored for the AlpineBits DestinationData standard.
            </Typography>
          </div>
          <Validator/>
        </Container>
      </div>
    );
  }
}

export default App;
