import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/styles';

import Console from './Console';
import JSONEditor from './JSONEditor';
import schemas from './schemas';

const Ajv = require('ajv');

class Validator extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      schema: '',
      schemaIndex: 4,
      schemaEditorMessages: [],
      object: '',
      exampleIndex: 1,
      objectEditorMessages: [],
      isLoading: true,
      error: {
        hasError: false,
        errorInSchema: null,
        messages: []
      },
      timer: null
    };
  }

  componentDidMount = () => {
    this.loadSchema(this.state.schemaIndex);
    this.loadExample(this.state.exampleIndex);
  }

  handleSchemaSelect = event => {
    this.setState({
      ...this.state,
      schemaIndex: event.target.value,
      exampleIndex: ''
    });

    this.loadSchema(event.target.value);
  };

  handleExampleSelect = event => {
    this.setState({
      ...this.state,
      exampleIndex: event.target.value
    });

    this.loadExample(event.target.value);
  };

  handleSchemaChange = value => {
    this.setState({
      ...this.state,
      schema: value,
    });

    this.validate();
  };

  handleObjectChange = value => {
    this.setState({
      ...this.state,
      object: value,
    });

    this.validate();
  };

  handleSchemaValidation = messages => {
    this.setState({
      ...this.state,
      schemaEditorMessages: messages
    });
  }

  handleObjectValidation = messages => {
    this.setState({
      ...this.state,
      objectEditorMessages: messages
    });
  }

  handleSchemaClick = () => {
    try {
      this.setState({
        ...this.state,
        schema: this.prettifyJSON(this.state.schema)
      });
    } catch (e) {}
  };

  handleObjectClick = () => {
    try {
      this.setState({
        ...this.state,
        object: this.prettifyJSON(this.state.object)
      });
    } catch (e) {}
  };

  prettifyJSON = (json) => {
    return JSON.stringify(JSON.parse(json), null, 3)
  }

  setError = (messages, errorInSchema) => {
    this.setState({
      ...this.state,
      isLoading: false,
      error: {
        hasError: true,
        errorInSchema: errorInSchema,
        messages: messages
      }
    });
  }

  parseJSON = messages => {
    if(messages.length>0)
      return [{
        text: messages[0].text + " at row " + messages[0].row + ' column ' + messages[0].column
      }];

    return [];
  }

  loadJSON = (fileName, fieldName) => {
    fetch(fileName)
      .then( response => response.text() )
      .then( value => {
        this.setState({
          ...this.state,
          [fieldName]: value
        });

        this.validate();
      })
  }

  loadSchema = schemaIndex => this.loadJSON(schemas[schemaIndex].file, 'schema')

  loadExample = exampleIndex => this.loadJSON(this.getExamples()[exampleIndex], 'object')

  validate = () => {
    if(!this.state.isLoading){
      this.setState({
        ...this.state,
        isLoading: true
      });
    }

    if(this.timer){
      window.clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {

      // validate schema syntax
      let syntaxErrors = this.parseJSON(this.state.schemaEditorMessages);
      if(syntaxErrors.length>0){
        this.setError(syntaxErrors, true)
        return;
      }

      // validate schema structure
      let ajv = new Ajv({
        $data: true,
        verbose: true,
        allErrors: true
      });

      let validate;
      try {
        validate = ajv.compile(JSON.parse(this.state.schema));
      }
      catch(e) {
        this.setError([{text: e.message}], true);
        return;
      }

      // validate object syntax
      syntaxErrors = this.parseJSON(this.state.objectEditorMessages);
      if(syntaxErrors.length>0){
        this.setError(syntaxErrors, false)
        return;
      }

      // validate object structure
      try {
        let valid = validate(JSON.parse(this.state.object));
        if(!valid){
          let errors = validate.errors.map( (error) => {
            return ({
              text: error.message,
              path: error.schemaPath
            });
          });
          this.setError(errors, false)
          return;
        }
      }
      catch(e) {
        this.setError([{text: e.message}], false);
        return;
      }

      // no errors were found
      this.setState({
        ...this.state,
        isLoading: false,
        error: {
          hasError: false,
          errorInSchema: null,
          messages: []
        }
      })
    }, 800);
  }

  getExamples = () => schemas[this.state.schemaIndex].examples

  renderSchemaSelect() {
    const { classes } = this.props;

    const items = schemas.map( (entry, idx) =>
      <MenuItem key={idx} value={idx}>{entry.schema}</MenuItem>);

    return(
      <Box className={classes.titleBox}>
        <FormControl className={classes.selectForm}>
          <InputLabel>Choose a schema to validate your object</InputLabel>
          <Select
            value={this.state.schemaIndex}
            onChange={this.handleSchemaSelect}
          >
            {items}
          </Select>
        </FormControl>
      </Box>
    );
  }

  renderExampleSelect() {
    const { classes } = this.props;
    const items = this.getExamples().map( (entry, idx) => {
      const name = entry.replace(/(.*)\/(.*?).example.json/,'$2.example.json');
      return (<MenuItem key={idx} value={idx}>{name}</MenuItem>);
    });

    return(
      <Box className={classes.titleBox}>
        <FormControl className={classes.selectForm}>
          <InputLabel>Choose an example or insert your own JSON below</InputLabel>
          <Select
            value={this.state.exampleIndex}
            onChange={this.handleExampleSelect}
          >
            {items}
          </Select>
        </FormControl>
      </Box>
    );

  }

  render() {
    // const { classes } = this.props;
    return (
      <div className="Validator">
        <Grid container direction="row" spacing={2}>
          <Grid item xs>
            <Box>
              {this.renderSchemaSelect()}
              <JSONEditor
                value={this.state.schema}
                onChange={this.handleSchemaChange}
                onValidate={this.handleSchemaValidation}
                onButtonClick={this.handleSchemaClick}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Box >
              {this.renderExampleSelect()}
              <JSONEditor
                value={this.state.object}
                onChange={this.handleObjectChange}
                onValidate={this.handleObjectValidation}
                onButtonClick={this.handleObjectClick}
              />
            </Box>
          </Grid>
        </Grid>
        <Console
          isLoading = {this.state.isLoading}
          {...this.state.error}
        />
      </div>
    );
  }
}

const styles = theme => ({
  selectForm: {
    minWidth: 400,
    width: '100%',
    '& label.Mui-focused': {
      color: '#F39101',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F39101',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#F39101',
    }
  },
  titleBox: {
    display: "flex",
    height: "48px"
  },
  typography: {
    marginTop: "auto"
  }
});

export default withStyles(styles)(Validator);
